<template>
  <span :class="['badge', props.visible === true ? 'badge--visible' : null]" />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    visible?: boolean
  }>(),
  { visible: false },
)
</script>

<style lang="scss" scoped>
@use 'sass:color';
@keyframes badge {
  0% {
    box-shadow:
      0 0 0.3em 0 #ffa500,
      inset 0 0 0.3em #ffa500;
  }

  50% {
    box-shadow:
      0 0 0.5em 0.1em #ffa500,
      inset 0 0 0.3em color.adjust(#ffa500, $lightness: 30%);
  }

  100% {
    box-shadow:
      0 0 0.3em 0 #ffa500,
      inset 0 0 0.3em #ffa500;
  }
}

.badge {
  opacity: 0;
  top: 0.2em;
  right: 0.2em;
  position: absolute;
  width: 0.45em;
  height: 0.45em;
  background: color.adjust(#ffa500, $lightness: 30%);
  border-radius: 100%;
  transform: scale(0.5);
  transition:
    opacity var(--transition-normal),
    transform var(--transition-normal);
  will-change: opacity, transform;
  animation: badge 1500ms infinite;

  &--visible {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
